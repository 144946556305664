



























































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from "vuex-class"

const AccountGetter = namespace('account').Getter

const AppProps = Vue.extend({
    methods: {
        showModal () {
            this.$modal.show('contact-us-modal');
            this.closeNav();
        },
        redirectToSignUpOrHome(isLoggedIn) {
            if(isLoggedIn) {
                this.$router.push('/');
            } else {
                this.$router.push('/signup');
            }
        }
    }
})
@Component({
    name: 'vip',
    mounted() {
        $("#ultra_container").addClass("barescreen");
        $("#ultra_container").addClass("pb-0");
        $("#center_outer_alley_contents").addClass("barescreen").removeClass("topbar_height_offset");
        $("#outer_alley_contents").addClass("barescreen");
        $("#alley_contents").addClass("barescreen");
        $("#gamearea_bordered").addClass("barescreen");
        $("#main_centering_div").addClass("barescreen");
        $("#topbar_dim_stretch").remove();
    }
})

export default class Vip extends AppProps {
    @AccountGetter isLoggedIn
}
